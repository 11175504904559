//
// Copyright 2018 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

// Public mixins

@mixin bo-text-field-helper-text-color($color) {
  &:not(.bo-text-field--disabled) {
    @include bo-text-field-helper-text-color_($color);
  }
}

@mixin bo-text-field-helper-text-validation-color($color) {
  &:not(.bo-text-field--disabled) {
    @include bo-text-field-helper-text-validation-color_($color);
  }
}

// Private mixins

@mixin bo-text-field-helper-text-color_($color) {
  + .bo-text-field-helper-text {
    @include bo-theme-prop(color, $color);
  }
}

@mixin bo-text-field-helper-text-validation-color_($color) {
  &.bo-text-field--invalid + .bo-text-field-helper-text--validation-msg {
    @include bo-theme-prop(color, $color);
  }
}
