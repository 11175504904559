//
// Copyright 2018 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import '../base/mixins';
@import '../theme/mixins';
@import './functions';
@import './mixins';

// Line Ripple is intended for use by multiple components, but its styles should only be emitted once when bundled
@include bo-base-emit-once('bo-line-ripple') {
  // postcss-bem-linter: define line-ripple
  .bo-line-ripple {
    height: 2px;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transition: bo-line-ripple-transition-value(transform), bo-line-ripple-transition-value(opacity);
    width: 100%;
    z-index: 2;
  }

  .bo-line-ripple--active {
    opacity: 1;
    transform: scaleX(1);
  }

  .bo-line-ripple--deactivating {
    opacity: 0;
  }
}
