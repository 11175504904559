//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
$bo-text-field-error: #b00020;
$bo-text-field-fullwidth-bottom-line-color: rgba(bo-theme-prop-value(on-surface), 0.12);
$bo-text-field-disabled-border: rgba(#231f20, 0.26);
$bo-text-field-disabled-icon: rgba(bo-theme-prop-value(on-surface), 0.3);
$bo-text-field-bottom-line-hover: rgba(bo-theme-prop-value(on-surface), 0.87);
$bo-text-field-bottom-line-idle: rgba(bo-theme-prop-value(on-surface), 0.42);
$bo-text-field-label: rgba(bo-theme-prop-value(on-surface), 0.6);
$bo-text-field-placeholder: rgba(bo-theme-prop-value(on-surface), 0.6);

$bo-text-field-ink-color: rgba(bo-theme-prop-value(on-surface), 0.87);
$bo-text-field-helper-text-color: rgba(bo-theme-prop-value(on-surface), 0.6);
$bo-text-field-icon-color: rgba(bo-theme-prop-value(on-surface), 0.54);
$bo-text-field-focused-label-color: rgba(bo-theme-prop-value(primary), 0.87);

$bo-text-field-disabled-label-color: rgba(bo-theme-prop-value(on-surface), 0.37);
$bo-text-field-disabled-ink-color: rgba(bo-theme-prop-value(on-surface), 0.37);
$bo-text-field-disabled-helper-text-color: rgba(bo-theme-prop-value(on-surface), 0.37);

$bo-text-field-box-background: mix(bo-theme-prop-value(on-surface), bo-theme-prop-value(surface), 4%);
$bo-text-field-box-disabled-background: mix(bo-theme-prop-value(on-surface), bo-theme-prop-value(surface), 2%);
$bo-text-field-box-secondary-text: rgba(bo-theme-prop-value(on-surface), 0.6);

$bo-text-field-outlined-idle-border: rgba(bo-theme-prop-value(on-surface), 0.24);
$bo-text-field-outlined-disabled-border: rgba(bo-theme-prop-value(on-surface), 0.06);
$bo-text-field-outlined-hover-border: rgba(bo-theme-prop-value(on-surface), 0.87);

$bo-textarea-border: rgba(bo-theme-prop-value(on-surface), 0.73);
$bo-textarea-background: rgba(bo-theme-prop-value(surface), 1);
// cannot be transparent because multiline textarea input
// will make text unreadable
$bo-textarea-disabled-background: rgba(249, 249, 249, 1);

$bo-text-field-border-radius: 4px !default;

$bo-text-field-box-label-position-y: 50%;
$bo-text-field-box-dense-label-position-y: 70%;
$bo-text-field-dense-label-scale: 0.923;
$bo-text-field-outlined-label-position-y: 130%;
$bo-text-field-outlined-dense-label-position-y: 110%;
$bo-text-field-outlined-with-leading-icon-label-position-x: 32px;
$bo-text-field-outlined-dense-with-leading-icon-label-position-x: 21px;
$bo-text-field-textarea-label-position-y: 50%;
// Note that the scale factor is an eyeballed approximation of what's shown in the mocks.
$bo-text-field-textarea-label-scale: 0.923;
