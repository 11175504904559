//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import './functions';

//
// Main theme colors for your brand.
//
// If you're a user customizing your color scheme in SASS, these are probably the only variables you need to change.
//

$bo-theme-primary: #6200ee !default; // baseline purple, 500 tone
$bo-theme-on-primary: if(bo-theme-contrast-tone($bo-theme-primary) == 'dark', #000000, #ffffff) !default;

// The $bo-theme-accent variable is DEPRECATED - it exists purely for backward compatibility.
// The $bo-theme-secondary* variables should be used for all new projects.
$bo-theme-accent: #018786 !default; // baseline teal, 600 tone
$bo-theme-secondary: $bo-theme-accent !default;
$bo-theme-on-secondary: if(bo-theme-contrast-tone($bo-theme-secondary) == 'dark', #000000, #ffffff) !default;
$bo-theme-background: #ffffff !default; // White

$bo-theme-surface: #ffffff !default;
$bo-theme-on-surface: if(bo-theme-contrast-tone($bo-theme-surface) == 'dark', #000000, #ffffff) !default;

//
// Text colors according to light vs dark and text type.
//

$bo-theme-text-colors: (
  dark: (
    primary: rgba(black, 0.87),
    secondary: rgba(black, 0.54),
    hint: rgba(black, 0.38),
    disabled: rgba(black, 0.38),
    icon: rgba(black, 0.38)
  ),
  light: (
    primary: white,
    secondary: rgba(white, 0.7),
    hint: rgba(white, 0.5),
    disabled: rgba(white, 0.5),
    icon: rgba(white, 0.5)
  )
) !default;

@function bo-theme-ink-color-for-fill_($text-style, $fill-color) {
  $contrast-tone: bo-theme-contrast-tone($fill-color);

  @return map-get(map-get($bo-theme-text-colors, $contrast-tone), $text-style);
}

//
// Primary text colors for each of the theme colors.
//

$bo-theme-property-values: (
  // Primary
  primary: $bo-theme-primary,
  // Secondary
  secondary: $bo-theme-secondary,
  // Background
  background: $bo-theme-background,
  // Surface
  surface: $bo-theme-surface,
  on-primary: $bo-theme-on-primary,
  on-secondary: $bo-theme-on-secondary,
  on-surface: $bo-theme-on-surface,
  // Text-primary on "background" background
  text-primary-on-background: bo-theme-ink-color-for-fill_(primary, $bo-theme-background),
  text-secondary-on-background: bo-theme-ink-color-for-fill_(secondary, $bo-theme-background),
  text-hint-on-background: bo-theme-ink-color-for-fill_(hint, $bo-theme-background),
  text-disabled-on-background: bo-theme-ink-color-for-fill_(disabled, $bo-theme-background),
  text-icon-on-background: bo-theme-ink-color-for-fill_(icon, $bo-theme-background),
  // Text-primary on "light" background
  text-primary-on-light: bo-theme-ink-color-for-fill_(primary, light),
  text-secondary-on-light: bo-theme-ink-color-for-fill_(secondary, light),
  text-hint-on-light: bo-theme-ink-color-for-fill_(hint, light),
  text-disabled-on-light: bo-theme-ink-color-for-fill_(disabled, light),
  text-icon-on-light: bo-theme-ink-color-for-fill_(icon, light),
  // Text-primary on "dark" background
  text-primary-on-dark: bo-theme-ink-color-for-fill_(primary, dark),
  text-secondary-on-dark: bo-theme-ink-color-for-fill_(secondary, dark),
  text-hint-on-dark: bo-theme-ink-color-for-fill_(hint, dark),
  text-disabled-on-dark: bo-theme-ink-color-for-fill_(disabled, dark),
  text-icon-on-dark: bo-theme-ink-color-for-fill_(icon, dark)
);

// If `$property` is a literal color value (e.g., `blue`, `#fff`), it is returned verbatim. Otherwise, the value of the
// corresponding theme property (from `$bo-theme-property-values`) is returned. If `$property` is not a color and no
// such theme property exists, an error is thrown.
//
// This is mainly useful in situations where `bo-theme-prop` cannot be used directly (e.g., `box-shadow`).
//
// Examples:
//
// 1. bo-theme-prop-value(primary) => "#3f51b5"
// 2. bo-theme-prop-value(blue)    => "blue"
//
// NOTE: This function must be defined in _variables.scss instead of _functions.scss to avoid circular imports.
@function bo-theme-prop-value($property) {
  @if type-of($property) == 'color' or $property == 'currentColor' {
    @return $property;
  }

  @if not map-has-key($bo-theme-property-values, $property) {
    @error "Invalid theme property: '#{$property}'. Choose one of: #{map-keys($bo-theme-property-values)}";
  }

  @return map-get($bo-theme-property-values, $property);
}

// NOTE: This function must be defined in _variables.scss instead of _functions.scss to avoid circular imports.
@function bo-theme-accessible-ink-color($fill-color, $text-style: primary) {
  $fill-color-value: bo-theme-prop-value($fill-color);
  $color-map-for-tone: map-get($bo-theme-text-colors, bo-theme-contrast-tone($fill-color-value));

  @if not map-has-key($color-map-for-tone, $text-style) {
    @error "Invalid $text-style: '#{$text-style}'. Choose one of: #{map-keys($color-map-for-tone)}";
  }

  @return map-get($color-map-for-tone, $text-style);
}
