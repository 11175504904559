// Theme palette
// Change these values to customise colour palettes

//$primary: (
//  color: $material-color-purple-500,
//  dark:  $material-color-purple-700,
//  light: $material-color-purple-100
//) !default;

//$secondary: (
//  color: $material-color-pink-a200,
//  dark:  $material-color-pink-a400,
//  light: $material-color-pink-a100
//) !default;

//$danger: (
//  color: $material-color-red-500,
//  dark:  $material-color-red-700,
//  light: $material-color-red-100
//) !default;

$primary: (
  color: #4285f4,
  dark:  darken(#4285f4, 10%),
  light: lighten(#4285f4, 10%)
) !default;

$secondary: (
  color: #4285f4,
  dark:  #000000,
  light: #757575
) !default;

$danger: (
  color: #d50000,
  dark:  darken(#d50000, 10%),
  light: lighten(#d50000, 10%)
) !default;

$info: (
  color: $material-color-blue-500,
  dark:  $material-color-blue-700,
  light: $material-color-blue-100
) !default;

$success: (
  color: $material-color-green-500,
  dark:  $material-color-green-700,
  light: $material-color-green-100
) !default;

$warning: (
  color: $material-color-orange-500,
  dark:  $material-color-orange-700,
  light: $material-color-orange-100
) !default;

$dark: (
  color: $material-color-grey-800,
  dark:  $material-color-grey-900,
  light: $material-color-grey-600
) !default;

$light: (
  color: $material-color-grey-100,
  dark:  $material-color-grey-300,
  light: $material-color-grey-050
) !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    primary:   $primary,
    secondary: $secondary,
    danger:    $danger,
    info:      $info,
    success:   $success,
    warning:   $warning,
    dark:      $dark,
    light:     $light
  ),
  $theme-colors
);

$yiq-contrasted-threshold: 150 !default;
$yiq-text-dark:            $black-primary !default;
$yiq-text-light:           $white-primary !default;
