//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import './variables';

@mixin bo-typography-base {
  @each $key, $value in $bo-typography-base {
    #{$key}: $value;
  }
}

@mixin bo-typography($style) {
  $style-props: map-get($bo-typography-styles, $style);

  @if not map-has-key($bo-typography-styles, $style) {
    @error "Invalid style specified! #{$style} doesn't exist. Choose one of #{map-keys($bo-typography-styles)}";
  }

  @each $key, $value in $style-props {
    #{$key}: $value;
  }
}

// Element must be `display: block` or `display: inline-block` for this to work.
@mixin bo-typography-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
