//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import '../animation/variables';
@import '../base/mixins';
@import '../rtl/mixins';
@import '../theme/variables';
@import '../theme/mixins';
@import '../typography/mixins';
@import './mixins';
@import './variables';

// Floating Label is intended for use by multiple components, but its styles should only be emitted once when bundled
@include bo-base-emit-once('bo-floating-label') {
  @include bo-floating-label-shake-keyframes(standard, $bo-floating-label-position-y);

  @at-root {
    @include bo-floating-label-float-position($bo-floating-label-position-y);
    @include bo-floating-label-shake-animation(standard);
  }
  // postcss-bem-linter: define floating-label
  .bo-floating-label {
    @include bo-typography(subtitle1);

    cursor: text;
    line-height: 1.15rem;
    position: absolute;
    bottom: 8px;
    left: 0;
    transform-origin: left top;
    transition:
      transform $bo-floating-label-transition-duration $bo-animation-standard-curve-timing-function,
      color $bo-floating-label-transition-duration $bo-animation-standard-curve-timing-function;

    @include bo-rtl {
      right: 0;
      left: auto;
      transform-origin: right top;
    }
  }

  .bo-floating-label--float-above {
    cursor: auto;
  }
}
