//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import './functions';

$bo-typography-font-family: roboto, sans-serif !default;

$bo-typography-base: (
  font-family: $bo-typography-font-family,
  -moz-osx-font-smoothing: grayscale,
  -webkit-font-smoothing: antialiased,
);

$bo-typography-font-weight-values: (
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900
) !default;

$bo-typography-styles: bo-typography-set-styles_(
  $bo-typography-base,
  (
    headline1: (
      font-size: 6rem,
      line-height: 6rem,
      font-weight: map-get($bo-typography-font-weight-values, light),
      letter-spacing: bo-typography-get-letter-spacing_(-1.5, 6),
      text-decoration: inherit,
      text-transform: inherit
    ),
    headline2: (
      font-size: 3.75rem,
      line-height: 3.75rem,
      font-weight: map-get($bo-typography-font-weight-values, light),
      letter-spacing: bo-typography-get-letter-spacing_(-0.5, 3.75),
      text-decoration: inherit,
      text-transform: inherit
    ),
    headline3: (
      font-size: 3rem,
      line-height: 3.125rem,
      font-weight: map-get($bo-typography-font-weight-values, regular),
      letter-spacing: normal,
      text-decoration: inherit,
      text-transform: inherit
    ),
    headline4: (
      font-size: 2.125rem,
      line-height: 2.5rem,
      font-weight: map-get($bo-typography-font-weight-values, regular),
      letter-spacing: bo-typography-get-letter-spacing_(0.25, 2.125),
      text-decoration: inherit,
      text-transform: inherit
    ),
    headline5: (
      font-size: 1.5rem,
      line-height: 2rem,
      font-weight: map-get($bo-typography-font-weight-values, regular),
      letter-spacing: normal,
      text-decoration: inherit,
      text-transform: inherit
    ),
    headline6: (
      font-size: 1.25rem,
      line-height: 2rem,
      font-weight: map-get($bo-typography-font-weight-values, medium),
      letter-spacing: bo-typography-get-letter-spacing_(0.25, 1.25),
      text-decoration: inherit,
      text-transform: inherit
    ),
    subtitle1: (
      font-size: 1rem,
      line-height: 1.75rem,
      font-weight: map-get($bo-typography-font-weight-values, regular),
      letter-spacing: bo-typography-get-letter-spacing_(0.15, 1),
      text-decoration: inherit,
      text-transform: inherit
    ),
    subtitle2: (
      font-size: 0.875rem,
      line-height: 1.375rem,
      font-weight: map-get($bo-typography-font-weight-values, medium),
      letter-spacing: bo-typography-get-letter-spacing_(0.1, 0.875),
      text-decoration: inherit,
      text-transform: inherit
    ),
    body1: (
      font-size: 1rem,
      line-height: 1.5rem,
      font-weight: map-get($bo-typography-font-weight-values, regular),
      letter-spacing: bo-typography-get-letter-spacing_(0.5, 1),
      text-decoration: inherit,
      text-transform: inherit
    ),
    body2: (
      font-size: 0.875rem,
      line-height: 1.25rem,
      font-weight: map-get($bo-typography-font-weight-values, regular),
      letter-spacing: bo-typography-get-letter-spacing_(0.25, 0.875),
      text-decoration: inherit,
      text-transform: inherit
    ),
    caption: (
      font-size: 0.75rem,
      line-height: 1.25rem,
      font-weight: map-get($bo-typography-font-weight-values, regular),
      letter-spacing: bo-typography-get-letter-spacing_(0.4, 0.75),
      text-decoration: inherit,
      text-transform: inherit
    ),
    button: (
      font-size: 0.875rem,
      line-height: 2.25rem,
      font-weight: map-get($bo-typography-font-weight-values, medium),
      letter-spacing: bo-typography-get-letter-spacing_(1.25, 0.875),
      text-decoration: none,
      text-transform: uppercase
    ),
    overline: (
      font-size: 0.75rem,
      line-height: 2rem,
      font-weight: map-get($bo-typography-font-weight-values, medium),
      letter-spacing: bo-typography-get-letter-spacing_(2, 0.75),
      text-decoration: none,
      text-transform: uppercase
    ),
  )
) !default;
