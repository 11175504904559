//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import '../theme/variables';
@import '../theme/mixins';

.bo-text-field--with-leading-icon .bo-text-field__icon,
.bo-text-field--with-trailing-icon .bo-text-field__icon {
  cursor: pointer;
  position: absolute;
  bottom: 16px;
}

.bo-text-field__icon:not([tabindex]),
.bo-text-field__icon[tabindex='-1'] {
  cursor: default;
  pointer-events: none;
}
