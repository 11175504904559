//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import './animation/variables';
@import './floating-label/floating-label';
@import './line-ripple/line-ripple';
@import './notched-outline/notched-outline';
@import './ripple/common';
@import './ripple/mixins';
@import './rtl/mixins';
@import './theme/variables';
@import './typography/mixins';
@import './typography/variables';
@import './functions';
@import './helper-text/text-field-helper-text';
@import './icon/text-field-icon';
@import './mixins';
@import './variables';

// postcss-bem-linter: define text-field

.bo-text-field {
  @include bo-text-field-bottom-line-color($bo-text-field-bottom-line-idle);
  @include bo-text-field-hover-bottom-line-color($bo-text-field-bottom-line-hover);
  @include bo-text-field-line-ripple-color_(primary);
  @include bo-text-field-ink-color($bo-text-field-ink-color);
  @include bo-text-field-label-color($bo-text-field-label);
  @include bo-text-field-helper-text-color($bo-text-field-helper-text-color);
  @include bo-text-field-fullwidth-bottom-line-color($bo-text-field-fullwidth-bottom-line-color);
  @include bo-text-field-icon-color($bo-text-field-icon-color);

  display: inline-block;
  margin-bottom: 8px;
  position: relative;
  will-change: opacity, transform, color;
}

.bo-text-field__input {
  @include bo-typography(subtitle1);

  appearance: none;
  background: none;
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  height: 30px;
  padding: 20px 0 1px;
  transition: bo-text-field-transition(opacity);
  width: 100%;

  &::placeholder {
    opacity: 1;
    transition: bo-text-field-transition(color);
  }

  &:focus {
    outline: none;
  }

  // Remove red outline on firefox
  &:invalid {
    box-shadow: none;
  }

  // Move label when text-field gets autofilled in Chrome
  // stylelint-disable plugin/selector-bem-pattern
  &:-webkit-autofill + .bo-floating-label {
    cursor: auto;
    transform: translateY(-100%) scale(0.75);
  }
  // stylelint-enable plugin/selector-bem-pattern
}

.bo-text-field--outlined {
  @include bo-text-field-outlined_;
}

.bo-text-field--outlined.bo-text-field--focused {
  @include bo-text-field-outlined-focused_;
}

.bo-text-field--outlined.bo-text-field--disabled {
  @include bo-text-field-outlined-disabled_;
}

.bo-text-field--outlined.bo-text-field--dense {
  @include bo-text-field-outlined-dense_;
}

.bo-text-field--box {
  @include bo-text-field-box_;
}

.bo-text-field--box.bo-text-field--disabled {
  @include bo-text-field-box-disabled_;
}

.bo-text-field--box.bo-text-field--dense {
  @include bo-text-field-box-dense_;
}

.bo-text-field--with-leading-icon {
  @include bo-text-field-with-leading-icon_;
}

.bo-text-field--with-leading-icon.bo-text-field--outlined {
  @include bo-text-field-outlined-with-leading-icon_;
}

.bo-text-field--with-leading-icon.bo-text-field--outlined.bo-text-field--dense {
  @include bo-text-field-outlined-dense-with-leading-icon_;
}

.bo-text-field--with-trailing-icon {
  @include bo-text-field-with-trailing-icon_;
}

// stylelint-disable plugin/selector-bem-pattern
.bo-text-field--with-leading-icon.bo-text-field--dense .bo-text-field__icon,
.bo-text-field--with-trailing-icon.bo-text-field--dense .bo-text-field__icon {
  bottom: 16px;
  transform: scale(0.8);
}
// stylelint-enable plugin/selector-bem-pattern

.bo-text-field--with-leading-icon.bo-text-field--dense {
  @include bo-text-field-dense-with-leading-icon_;
}

.bo-text-field--with-trailing-icon.bo-text-field--dense {
  @include bo-text-field-dense-with-trailing-icon_;
}

.bo-text-field--upgraded:not(.bo-text-field--fullwidth):not(.bo-text-field--box) {
  align-items: flex-end;
  box-sizing: border-box;
  display: inline-flex;
  margin-top: 16px;
  position: relative;

  // stylelint-disable-next-line selector-max-specificity
  &:not(.bo-text-field--textarea):not(.bo-text-field--outlined) {
    height: 48px;
  }
}

.bo-text-field--dense {
  @include bo-text-field-dense_;
}

@include bo-required-text-field-label-asterisk_ {
  content: '*';
  margin-left: 1px;
}

.bo-text-field--textarea {
  @include bo-text-field-textarea_;
}

.bo-text-field--fullwidth {
  @include bo-text-field-fullwidth_;
}

.bo-text-field--fullwidth.bo-text-field--invalid {
  @include bo-text-field-fullwidth-invalid_;
}

// postcss-bem-linter: define text-field-helper-text

.bo-text-field-helper-text {
  // stylelint-disable plugin/selector-bem-pattern
  .bo-text-field--dense + & {
    margin-bottom: 4px;
  }

  .bo-text-field--box + &,
  .bo-text-field--outlined + & {
    margin-right: 16px;
    margin-left: 16px;
  }

  // stylelint-enable plugin/selector-bem-pattern
}

// postcss-bem-linter: end

// bo-form-field tweaks to align text field label correctly
// stylelint-disable selector-max-type
.bo-form-field > .bo-text-field + label {
  align-self: flex-start;
}
// stylelint-enable selector-max-type

.bo-text-field--focused {
  @include bo-text-field-focused_;
}

.bo-text-field--textarea.bo-text-field--focused {
  @include bo-text-field-textarea-stroke-color(primary);
}

.bo-text-field--invalid {
  @include bo-text-field-invalid_;
}

.bo-text-field--textarea.bo-text-field--invalid {
  @include bo-text-field-textarea-invalid_;
}

.bo-text-field--outlined.bo-text-field--invalid {
  @include bo-text-field-outlined-invalid_;
}

.bo-text-field--disabled {
  @include bo-text-field-disabled_;
}

.bo-text-field--textarea.bo-text-field--disabled {
  @include bo-text-field-textarea-disabled_;
}

@include bo-floating-label-shake-keyframes(text-field-box, $bo-text-field-box-label-position-y);
@include bo-floating-label-shake-keyframes(text-field-box-dense, $bo-text-field-box-dense-label-position-y, 0%, $bo-text-field-dense-label-scale);
@include bo-floating-label-shake-keyframes(text-field-outlined, $bo-text-field-outlined-label-position-y);
@include bo-floating-label-shake-keyframes(text-field-outlined-dense, $bo-text-field-outlined-dense-label-position-y, 0%, $bo-text-field-dense-label-scale);
@include bo-floating-label-shake-keyframes(text-field-outlined-leading-icon, $bo-text-field-outlined-label-position-y, $bo-text-field-outlined-with-leading-icon-label-position-x);
@include bo-floating-label-shake-keyframes(text-field-outlined-leading-icon-dense, $bo-text-field-outlined-dense-label-position-y, $bo-text-field-outlined-dense-with-leading-icon-label-position-x, $bo-text-field-dense-label-scale);
@include bo-floating-label-shake-keyframes(text-field-outlined-leading-icon-rtl, $bo-text-field-outlined-label-position-y, -$bo-text-field-outlined-with-leading-icon-label-position-x);
@include bo-floating-label-shake-keyframes(text-field-outlined-leading-icon-dense-rtl, $bo-text-field-outlined-dense-label-position-y, -$bo-text-field-outlined-dense-with-leading-icon-label-position-x, $bo-text-field-dense-label-scale);
@include bo-floating-label-shake-keyframes(textarea, $bo-text-field-textarea-label-position-y, 0%, $bo-text-field-textarea-label-scale);
