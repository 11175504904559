//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import '../functions';
@import '../theme/mixins';
@import '../typography/mixins';

// postcss-bem-linter: define text-field-helper-text

.bo-text-field-helper-text {
  @include bo-typography(caption);

  font-size: 0.75rem;
  margin: 0;
  opacity: 0;
  transition: bo-text-field-transition(opacity);
  will-change: opacity;

  // stylelint-disable plugin/selector-bem-pattern
  .bo-text-field + & {
    margin-bottom: 8px;
  }

  // stylelint-enable plugin/selector-bem-pattern
}

.bo-text-field-helper-text--persistent {
  opacity: 1;
  transition: none;
  will-change: initial;
}

// postcss-bem-linter: end
